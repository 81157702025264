import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";
import Image from "gatsby-image";
import { theme } from "../../../utils/global-styles/theme";
import DynamicForm from "../../shared/DynamicForm/DynamicForm";
import { _post } from "../../../utils/services/api.service";
import CloseIcon from "../../shared/CloseIcon";
import {
  isLoggedIn,
  getUserDetails,
  isValidEmail,
} from "../../../utils/services/methods";
import MarketingModalRichText from "./MarketingModalRichText";

const MarketingModal = ({
  marketingModal,
  isMarketingModalShowing,
  setIsMarketingModalShowing,
}) => {
  const Wrapper = styled.div`
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.4);
      font-family: ${theme.fontFaces.paragraphs};
    `,
    ModalContent = styled.div`
      background-color: white;
      margin: 5% auto;
      border: 1px solid #888;
      width: 80%;
      overflow: auto;
    `,
    Row = styled.div`
      display: flex;
      flex-direction: row;
      width: 100%;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        flex-direction: column;
      }
    `,
    Details = styled.div`
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 100px 50px 50px 50px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        width: 100%;
      }
    `,
    Title = styled.div`
      font-size: 3em;
      font-weight: bold;
      margin-bottom: 20px;
      line-height: 1em;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 2em;
      }
    `,
    Description = styled.div`
      margin-bottom: 20px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 1em;
      }
    `,
    CloseIconWrapper = styled.div`
      padding: 20px;
      position: absolute;
      right: 10%;
      cursor: pointer;
    `,
    AdditionalText = styled.div`
      width: 100%;
      font-size: 0.85em;
    `,
    {
      title,
      description,
      cta,
      marketingModalImage,
      showAfterPercent,
      hubspotListId,
      formFields,
      successTitle,
      successDescription,
      additionalText,
    } = marketingModal,
    [showSuccess, setShowSuccess] = useState(false),
    [scrollLevel, setScrollLevel] = useState(0),
    [isPastScrollLevel, setIsPastScrollLevel] = useState(false),
    scrollListener = () => {
      const footer = document.getElementById("footer"),
        footerHeight = footer.clientHeight,
        winScroll =
          document.body.scrollTop || document.documentElement.scrollTop,
        height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight -
          footerHeight,
        scrolled = (winScroll / height) * 100;
      setScrollLevel(scrolled);
    },
    dynamicFormValues = () => {
      const result = [];

      for (let i = 0; i < formFields.length; i++) {
        let formFieldObject = {
          name: formFields[i],
          label: formFields[i],
          required: true,
          inputType: "text",
          styles: {
            labelStyles: css`
              font-weight: bold;
              position: relative;
              top: -0.75em;
              background-color: white;
              padding: 0 8px;
              left: 4px;
              text-transform: capitalize;
            `,
            valueStyles: css`
              font-size: 18px;
            `,
            wrapperStyles: css`
              border-color: ${theme.colors.accent};
              border-width: 2px;
              border-style: solid;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px 0px 16px 12px;
              margin: 20px 0 20px 0;
            `,
            labelClassNames: "primary",
          },
        };

        if (formFields[i] === "full name") {
          if (isLoggedIn()) {
            formFieldObject.required = false;
            formFieldObject.styles.wrapperStyles = css`
              display: none !important;
            `;
          }

          formFieldObject.handleValidation = (event, setError) => {
            if (event.target.value === "") {
              setError("Please enter your full name");
            } else {
              setError(null);
            }
          };
        }

        if (formFields[i] === "email") {
          formFieldObject.handleValidation = (event, setError) => {
            if (!isValidEmail(event.target.value)) {
              setError("Please enter a valid email address.");
            } else {
              setError(null);
            }
          };
        }

        result.push(formFieldObject);
      }

      return result;
    },
    handleSubmit = async (event, value) => {
      const argsObject = {
        email: value.email,
        category_id: hubspotListId,
      };
      const userData = getUserDetails();
      const nameInput = value["full name"]?.split(" ");

      if (formFields[0] === "full name" || formFields[1] === "full name") {
        if (isLoggedIn()) {
          argsObject["first_name"] = userData?.first_name;
          argsObject["last_name"] = userData?.last_name;
        } else {
          argsObject["first_name"] = nameInput[0];
          argsObject["last_name"] =
            typeof nameInput[1] === "undefined" ? "" : nameInput[1];
        }
      } else {
        argsObject["first_name"] = "";
        argsObject["last_name"] = "";
      }

      setShowSuccess(true);

      await _post({
        args: argsObject,
        endpoint: "newsletters",
        isPublic: true,
      });
    },
    closeModal = () => {
      setIsMarketingModalShowing(false);
      localStorage.setItem(
        "marketingModal",
        JSON.stringify({
          description: description.description,
          showMarketingModal: false,
        })
      );
    };

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);

    if (scrollLevel > showAfterPercent) {
      setIsPastScrollLevel(true);
    }
    return () => window.removeEventListener("scroll", scrollListener);
  });

  return (
    <>
      {isPastScrollLevel && isMarketingModalShowing ? (
        <Global
          styles={css`
            body {
              overflow: hidden;
            }
          `}
        />
      ) : null}
      <Wrapper
        css={css`
          display: ${isMarketingModalShowing && isPastScrollLevel
            ? "block"
            : "none"};
        `}
      >
        <ModalContent>
          <Row>
            <Details>
              <Title>{showSuccess ? successTitle : title}</Title>
              <Description>
                {showSuccess ? successDescription : description.description}
              </Description>

              {!showSuccess ? (
                <DynamicForm
                  formValues={dynamicFormValues()}
                  confirmButton={{
                    buttonText: `${cta.linkText}`,
                    styles: {
                      buttonStyle: css`
                        width: 50%;
                        margin-bottom: 25px;
                        @media only screen and (max-width: ${theme.breakpoints
                            .helper800}) {
                          width: 100%;
                          margin-left: 0%;
                        }
                      `,
                    },
                    confirmButtonClasses: `black-button`,
                  }}
                  submit={handleSubmit}
                />
              ) : null}

              {!showSuccess && (
                <MarketingModalRichText richText={additionalText?.json} />
              )}
            </Details>
            <Image
              css={css`
                width: 50%;

                @media only screen and (max-width: ${theme.breakpoints
                    .helper800}) {
                  display: none;
                }
              `}
              fluid={marketingModalImage.image.fluid}
            />
            <CloseIconWrapper
              className={`primary-bg`}
              onClick={() => closeModal()}
            >
              <CloseIcon
                styles={`
                        float: right;
                        font-weight: bold;
                        `}
                width={"15px"}
                height={"15px"}
                color={"white"}
              />
            </CloseIconWrapper>
          </Row>
        </ModalContent>
      </Wrapper>
    </>
  );
};

export default MarketingModal;
